<template>
  <div class="app-container">
    <Title :data="data" />
    <div style="display: flex">
      <div style="width: 80%">
        <batchadd
          title="作业"
          text="作业"
          :dataList="form.excellent_homework"
          @edit="clickUpload('excellent_homework')"
          style="margin-bottom: 20px"
        ></batchadd>
        <batchadd
          title="试卷"
          text="试卷"
          :dataList="form.excellent_answer"
          @edit="clickUpload('excellent_answer')"
          style="margin-bottom: 20px"
        ></batchadd>
        <!-- <batchadd
          title="成绩表"
          text="成绩表"
          :dataList="form.school_report"
          @edit="clickUpload('school_report')"
          style="margin-bottom: 20px"
        ></batchadd> -->
      </div>
      <Uptips style="width: 20%"></Uptips>
    </div>
    <div class="bottom_btn">
      <el-button @click="$router.go(-1)">返回</el-button>
      <el-button type="primary" @click="submit" :loading="btnLoading"
        >保存并提交</el-button
      >
    </div>
  </div>
</template>

<script>
// import BatchAdd from "@/components/Batch/index.vue";
import batchadd from "@/components/Batch_add/index.vue";
import request from "@/utils/request";
export default {
  components: {
    batchadd,
  },
  data() {
    return {
      selected: false,
      btnLoading: false,
      list: [],
      school_report: [],
      excellent_answer: [],
      excellent_homework: [],
      listQuery: {
        type: "",
        class_course_id: "",
      },
      form: null,
      data: {},
    };
  },
  created() {
    this.listQuery.type = this.$route.query.type;
    this.data = JSON.parse(this.$route.query.data);
    this.listQuery.class_course_id = this.$route.query.class_course_id;
    localStorage.setItem("classdata", JSON.stringify(this.data));

    this.form = {
      excellent_homework: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "excellent_homework",
        data: [],
      },
      excellent_answer: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "excellent_answer",
        data: [],
      },
      school_report: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "school_report",
        data: [],
      },
    };
    this.getDetail();
  },
  methods: {
    clickUpload(e) {
      switch (e) {
        case "excellent_homework":
          this.form.excellent_homework.is_store = 1;
          break;
        case "excellent_answer":
          this.form.excellent_answer.is_store = 1;
          break;
        case "school_report":
          this.form.school_report.is_store = 1;
          break;
      }
    },
    getDetail() {
      request({
        url: "/api/teacherend/eduAdmin/dataDetail",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        if (response.data) {
          if (response.data.excellent_homework)
            this.form.excellent_homework = response.data.excellent_homework;
          if (response.data.excellent_answer)
            this.form.excellent_answer = response.data.excellent_answer;
          if (response.data.school_report)
            this.form.school_report = response.data.school_report;
        }
      });
    },
    submit() {
      if (
        this.form.excellent_homework.is_store != 1 &&
        this.form.excellent_answer.is_store != 1 &&
        this.form.school_report.is_store != 1
      ) {
        this.$alert("请修改或者上传后提交", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            return;
          },
        });
      }
      let submitForm = {};
      if (this.form.excellent_homework.is_store) {
        submitForm.excellent_homework = this.form.excellent_homework;
      }
      if (this.form.excellent_answer.is_store) {
        submitForm.excellent_answer = this.form.excellent_answer;
      }
      if (this.form.school_report.is_store) {
        submitForm.school_report = this.form.school_report;
      }
      if (Object.keys(submitForm).length == 0) {
        return;
      }
      request({
        url: "/api/teacherend/eduAdmin/dataStore",
        method: "post",
        data: submitForm,
      })
        .then((response) => {
          this.btnLoading = false;
          this.$message({
            message: "提交成功,待审核!",
            type: "success",
          });
          this.getDetail();
          // this.$router.go(0);
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
    addList() {
      this.list.push({ name: "" });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.content {
  width: 50%;
  margin: 0 auto;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }
}
.bottom_btn {
  display: flex;
  justify-content: center;
}
</style>
